html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
}

:not(i) {
  font-family: Inter, sans-serif !important;
}

::-webkit-input-placeholder {
  /* Edge */
  font-size: 12px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 12px;
}

::placeholder {
  font-size: 12px;
}

h6 {
  font-size: 16px !important;
}

li,
a,
b,
p,
input,
span,
label,
td,
div {
  font-weight: normal;
  font-size: 16px;
}

button {
  font-weight: bold;
  font-size: 15px;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
       supported by Chrome, Opera and Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

.gm-style .gm-style-iw-c {
  max-width: 400px !important;
  max-height: 200px !important;
  min-width: 0px !important;
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: -75px;
  left: 0;
  transform: translate(-50%, -100%);
}

.gm-style .gm-style-iw-t::after {
  top: -75px;
}

div.gallery {
  width: 300px;
  height: 300px;
  border: 10px solid white;
  border-radius: 50%;
}

div.gallery:hover {
  border: 10px solid rgb(240, 238, 238);
}

div.gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  object-fit: cover;
}

div.desc1 {
  padding: 15px;
  text-align: center;
  width: 70%;
}

.card {
  background-color: rgb(235 235 235/85%);
  width: 50%;
  text-align: center;
  padding: 20px;
  border-radius: 20px;
  font-size: 16pt;
  margin-bottom: 50px;
}

.card1 {
  background-color: rgb(235 235 235/85%);
  text-align: left;
  padding: 20px;
  border-radius: 20px;
  font-size: 16pt;
}

div.desc2 {
  padding: 15px;
  text-align: center;
  width: 80%;
  position: relative;
}

.card2 {
  position: absolute;
  bottom: 0;
  right: 15px;
  background-color: rgb(235 235 235/85%);
  text-align: left;
  padding: 20px;
  border-radius: 20px;
  font-size: 16pt;
  width: 80%;
}

* {
  box-sizing: border-box;
}

.responsive {
  display: flex;
  padding: 0 6px;
  float: left;
  width: 100%;
  justify-content: center;
}

@media only screen and (max-width: 700px) {
  .responsive {
    width: 49.99999%;
    margin: 6px 0;
  }
}

@media only screen and (max-width: 500px) {
  .responsive {
    width: 100%;
  }
}

.carousel .slider-wrapper {
  width: 100%;
}

.MuiToolbar-gutters {
  padding: 0px !important;
}

.MuiDialogTitle-root {
  color: #0c59cf;
  font-size: 16;
}

.MuiDialogActions-root {
  justify-content: center !important;
}

.css-1jx2aec-MuiFormHelperText-root {
  display: none;
}

.logo_img {
  width: 95%;
  height: auto;
}

.logo_img_topbar {
  width: 15%;
  height: auto;
  z-index: 99;
}

.logo_img_map {
  width: 112px;
  padding-top: 4px;
  z-index: 99;
}

.logo_sidebar_img {
  width: 80%;
  height: 40px;
}

.logo_img_middle {
  width: 55%;
  height: auto;
}

.logo_img_how_middle {
  width: 30%;
  height: auto;
}

.logo_img_mobile {
  width: 100%;
  height: 40px;
}

.react-photo-gallery--gallery > :first-child {
  justify-content: space-between;
  overflow-x: hidden;
}
.react-photo-gallery--gallery > :first-child {
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  width: 100%;
  margin: auto;
  overflow-x: hidden;
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
}
@media screen and (max-width: 470px) {
  .react-photo-gallery--gallery > :first-child {
    grid-column-gap: 25px;
    grid-row-gap: 15px;
    width: 100%;
    margin: auto;
    overflow-x: hidden;
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
  }
}
span.lazy-load-image-background.blur.lazy-load-image-loaded {
  width: 100% !important;
}

.CarouselItem {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.CarouselItem div :nth-child(2) div {
  opacity: 1;
  transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 90vw;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-range__track--active,
.input-range__slider {
  color: #0c59cf !important;
  background: #0c59cf !important;
}
.input-range__track--background {
  border: 1px solid;
}

@media screen and (max-height: 500px) {
  .CarouselItem {
    height: 100vh !important;
  }
  .CarouselItem div :nth-child(2) div {
    /* margin-top: 40px; */
    height: 100vh !important;
  }
}

@media screen and (max-width: 768px) {
  .react-photo-gallery--gallery > :first-child {
    justify-content: center;
  }
  .CarouselItem div :nth-child(2) div {
    opacity: 1;
    transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 200vw;
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 500px) {
  .CarouselItem div :nth-child(2) div {
    opacity: 1;
    transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: fit-content;
    height: fit-content;
    /* display: flex;
        justify-content: center;
        align-items: center; */
  }
}

.zoom_slider_wrapper > :first-child {
  height: 100vh;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.zoom_slider_wrapper > :first-child .sc-AxhUy {
  width: 100%;
  background-color: black;
  position: absolute;
  top: 0px;
  height: 0px;
}

.ksrezC {
  margin: auto;
}

.barBtn {
  background-color: transparent;
}

/* For www.simplify.com payment gateway */

#simplify-checkout-embed-frame {
  border: none;
  height: 450px;
  overflow: hidden;
}
#active_tab {
  background: #d6d6d6;
}

#rfs-btn span span:last-child {
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  .react-photo-album--row {
    display: block !important;
  }
}

/* override legacy display */
.legacymobileeventlistwrapper .gallery-card,
.legacymobileeventlistwrapper .gallery-event-card {
  margin-top: 0px;
  height: auto;
}
.legacymobileeventlistwrapper .no-galleries {
  top: inherit;
  position: relative;
}

/* styles react-google-map autocomplete results */

@media screen and (max-width: 767px) {
  .pac-container {
    width: 100dvw !important;
  }
}
.pac-item {
  color: #888;
  padding: 8px 10px;
}
.pac-item .pac-item-query {
  font-size: 16px;
  color: #bbb;
}
.pac-item .pac-item-query .pac-matched {
  font-weight: normal;
  font-size: 16px;
  color: black;
}

.react-photo-album--row > div {
  overflow: hidden;
}

.react-photo-album--row > div img {
  transition: transform 0.5s ease 0s !important;
  transform: scale(1);
}
.react-photo-album--row > div img:hover {
  transform: scale(1.3);
}

.v3 .galleryphotographscontainer {
  width: 100% !important;
}
.v3 .filter_num_photos_text {
  top: 0;
  margin-bottom: 0;
}
