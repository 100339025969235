.overlayicon {
  color: #158de0;
  background-color: white;
  border: solid 3px #158de0;
  border-radius: 35px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.overlayicon:hover {
  background-color: #29a6ff;
}
.overlayiconselected {
  color: white;
  background-color: #158de0;
}
.overlayiconmobile {
  width: 45px;
  height: 45px;
}
